import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { requireUser } from "@/services/user";
import { axios } from "./axiosFactory";

export const TokenHeader = "AuthorizationToken";

export const LocaleQueryParamName = "webPrefixCode"; // Remove "export" when old api client will be removed
export const LocaleQueryParamValue = "cz"; // Hardcoded - no translations in admin yet
export const CurrencyIdQueryParamName = "primaryCurrencyId"; // Remove "export" when old api client will be removed
export const CurrencyIdQueryParamValue = 1; // Hardcoded - in admin display everything in CZK = 1

export const API = {
  async get<ResponseDataType>(url: string, params: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest<ResponseDataType>("GET", url, params, {}, true);
  },

  async getPublic<ResponseDataType>(url: string, params: object = {}, addAuthToken = false): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("GET", url, params, {}, addAuthToken);
  },

  async post<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("POST", url, params, data, true);
  },

  async postPublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken = false): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("POST", url, params, data, addAuthToken);
  },

  async put<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PUT", url, params, data, true);
  },

  async putPublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken = false): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PUT", url, params, data, addAuthToken);
  },

  async delete<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("DELETE", url, params, data, true);
  },

  async deletePublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken = false): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("DELETE", url, params, data, addAuthToken);
  },

  async patch<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PATCH", url, params, data, true);
  },

  async patchPublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken = false): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PATCH", url, params, data, addAuthToken);
  },
};

async function makeRequest<ResponseDataType>(method: Method, url: string, params: object = {}, data: object = {}, addUserToken = true): Promise<AxiosResponse<ResponseDataType>> {
  const config = <AxiosRequestConfig>{
    url,
    method,
    params: {
      ...params,
      [LocaleQueryParamName]: LocaleQueryParamValue,
      [CurrencyIdQueryParamName]: CurrencyIdQueryParamValue,
    },
    data,
  };

  if (addUserToken) {
    addToken(config);
  }

  return axios.request<ResponseDataType>(config);
}

function addToken(config: AxiosRequestConfig): void {
  if (typeof config.headers === "undefined") {
    config.headers = {};
  }

  config.headers[TokenHeader] = requireUser().getToken();
}
